<template>
    <div>
        <div class="setmenu1">
            <el-form :label-position="labelPosition" label-width="150px" :model="formLabelAlign">
                <el-form-item label="传单价格">
                    <el-input v-model="formLabelAlign.leaflet_money"></el-input>
                </el-form-item>
                <el-form-item label="发布次数价格">
                    <el-input v-model="formLabelAlign.send_count_money"></el-input>
                </el-form-item>
                <el-form-item label="默认赠送发布次数">
                    <el-input v-model="formLabelAlign.send_count"></el-input>
                </el-form-item>
                <el-form-item label="社群二维码">
                    <el-image v-show="formLabelAlign.commit_qrcode" style="width: 178px; height: 178px"
                        :src="formLabelAlign.commit_qrcode" fit="cover"></el-image>
                    <div class="delbtn"><el-button type="danger" v-show="formLabelAlign.commit_qrcode" icon="el-icon-delete"
                            @click="delcommunity(1)" circle></el-button></div>
                    <el-upload class="avatar-uploader" v-show="!formLabelAlign.commit_qrcode"
                        action="https://dyuankang.cn:3000/uploads/upload" :show-file-list="false"
                        :on-success="handleAvatarSuccess1" name="image" :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl1" :src="imageUrl1" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="联系二维码">
                    <el-image v-show="formLabelAlign.customer_qrcode" style="width: 178px; height: 178px"
                        :src="formLabelAlign.customer_qrcode" fit="cover"></el-image>
                    <div class="delbtn"><el-button type="danger" v-show="formLabelAlign.customer_qrcode"
                            icon="el-icon-delete" @click="delcommunity(2)" circle></el-button></div>
                    <el-upload class="avatar-uploader" v-show="!formLabelAlign.customer_qrcode"
                        action="https://dyuankang.cn:3000/uploads/upload" :show-file-list="false"
                        :on-success="handleAvatarSuccess2" name="image" :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl2" :src="imageUrl2" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item label="分享地址">
                    <el-input v-model="formLabelAlign.share_address"></el-input>
                </el-form-item>
                <el-form-item label="默认收款码">
                    <el-image v-show="formLabelAlign.pay_img" style="width: 178px; height: 178px"
                        :src="formLabelAlign.pay_img" fit="cover"></el-image>
                    <div class="delbtn"><el-button type="danger" v-show="formLabelAlign.pay_img" icon="el-icon-delete"
                            @click="delcommunity(3)" circle></el-button></div>
                    <el-upload class="avatar-uploader" v-show="!formLabelAlign.pay_img" name="image"
                        action="https://dyuankang.cn:3000/uploads/upload" :show-file-list="false"
                        :on-success="handleAvatarSuccess3" :before-upload="beforeAvatarUpload">
                        <img v-if="imageUrl3" :src="imageUrl3" class="avatar">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
            </el-form>
            <el-button type="primary" @click="update_config_admin()">保存</el-button>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            labelPosition: 'left',
            formLabelAlign: {
                leaflet_money: '',
                send_count_money: '',
                send_count: '',
                commit_qrcode: '',
                customer_qrcode: '',
                share_address: '',
                pay_img: '',
            },
            imageUrl1: '',
            imageUrl2: '',
            imageUrl3: ''

        }
    },
    mounted() {
        this.get_config_admin();
        this.formLabelAlign.acitons = this.$api.uploads
    },
    methods: {
        delcommunity(type) {
            switch (type) {
                case 1:
                    this.formLabelAlign.commit_qrcode = "";
                    break;
                case 2:
                    this.formLabelAlign.customer_qrcode = "";
                    break;
                case 3:
                    this.formLabelAlign.pay_img = "";
                    break;
            }
        },
        get_config_admin() {
            this.$request.post(this.$api.get_config_admin).then(res => {
                if (res.code == 0) {
                    this.formLabelAlign = res.data
                }
            })
        },
        handleAvatarSuccess1(res, file) {
            this.formLabelAlign.commit_qrcode = res.filename;
            this.imageUrl1 = URL.createObjectURL(file.raw);
        },
        handleAvatarSuccess2(res, file) {
            this.formLabelAlign.customer_qrcode = res.filename;
            this.imageUrl2 = URL.createObjectURL(file.raw);
        },
        handleAvatarSuccess3(res, file) {
            this.formLabelAlign.pay_img = res.filename;
            this.imageUrl3 = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            // const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        update_config_admin() {
            this.$request.post(this.$api.update_config_admin, this.formLabelAlign).then(res => {
                if (res.code == 0) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.get_config_admin()
                }else{
                    this.$message.error(res.msg);
                }
            })
        }
    }
}


</script>

<style>
.setmenu1 {
    width: 500px;

}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px !important;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}

.el-form-item__content {
    display: flex;
    align-items: flex-end;
}

.delbtn {
    margin-left: 10px;
}
</style>