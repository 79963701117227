module.exports = {
    // 登录接口
    login:"api/login",
    // 查询工作
    get_job_admin:"admin/get_job_admin",
    approve_work:"admin/approve_work",//审核工作
    // 查询入驻信息
    get_agent_admin:"admin/get_agent_admin",
    approve_agent:"admin/approve_agent",
    delete_job_admin:"admin/delete_job_admin",
    
    send_count_admin:"admin/send_count_admin",
    approve_send_count:"admin/approve_send_count",

    // 用户
    get_user_admin:"admin/get_user_admin",

    // 获取项目基础信息
    get_config_admin:"admin/get_config_admin",
    update_config_admin:"admin/update_config_admin",

    // 上传图片
    upload:"https://dyuankang.cn:3000/uploads",

    // 传单
    get_leaflet:"admin/get_leaflet",

    // 轮播图
    get_swiper:"index/get_swiper",
    add_swiper:"admin/add_swiper",
    delete_swiper:"admin/delete_swiper",

    // 审核传单
    approve_leaflet:"admin/approve_leaflet",

    // 修改用户发布次数
    change_send_count_admin:"admin/change_send_count_admin",

    // 获取工作类型
    get_job_type:"index/get_job_subscribe",

    // 添加工作类型 add_subscribe_admin
    add_subscribe_admin:"admin/add_subscribe_admin",
    // 删除工作类型 delete_subscribe_admin
    delete_subscribe_admin:"admin/delete_subscribe_admin",
    // 查询用户推广人数 get_user_invite
    get_user_invite:"admin/get_user_invite",
}