<template>
    <div>
        <div class="qiye1" v-show="tableData.length>0">
            <el-table :data="tableData" height="100%" border style="width: 100%">
                <el-table-column prop="atime" label="上传时间" width="180">
                </el-table-column>
                <el-table-column prop="price" label="金额" width="240">
                </el-table-column>
                <el-table-column prop="nickname" label="用户昵称">
                </el-table-column>
                <el-table-column  label="用户收款码">
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.pament_code"
                            fit="fill" :preview-src-list="[scope.row.pament_code]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="传单">
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.cover"
                            fit="fill" :preview-src-list="[scope.row.cover]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="审核时间" prop="ap_time">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button v-show="scope.row.status==0" size="mini" type="primary" @click="handleApprove(scope.row,1)">通过</el-button>
                        <el-button v-show="scope.row.status==0" size="mini" type="danger" @click="handleDelete(scope.row,2)">拒绝</el-button>
                        <el-button v-show="scope.row.status==1" size="mini" type="success">已通过</el-button>
                        <el-button v-show="scope.row.status==2" size="mini" type="info">已拒绝</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-empty v-show="tableData.length==0" description="暂无入驻信息"></el-empty>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            page: 0,
            limit: 10,
        }
    },
    mounted() {
        this.get_leaflet()
    },
    methods: {
        get_leaflet() {
            this.$request.post(this.$api.get_leaflet, {
               
            }).then(res => {
                if(res.code==0){
                    res.data.forEach(item=>{
                        this.tableData.push(item)
                    })
                }
            })
        },
        handleApprove(item,status){
            this.$request.post(this.$api.approve_leaflet,{
                id:item.id,
                status:status
            }).then(res=>{
                if(res.code==0){
                    this.tableData = [];
                    this.get_leaflet();
                }
            }).catch(err=>{
                console.error(err);
            })
        },
        handleDelete(item,status){
            this.$request.post(this.$api.approve_leaflet,{
                id:item.id,
                status:status
            }).then(res=>{
                if(res.code==0){
                    this.tableData = [];
                    this.get_leaflet();
                }
            }).catch(err=>{
                console.error(err);
            })
        }
    }
}
</script>

<style>
.qiye1 {
    height: 80vh;
}
</style>