<template>
    <div>
        <div class="qiye1" v-show="tableData.length > 0">
            <el-table :data="tableData" height="100%" border style="width: 100%">
                <el-table-column type="index" label="序号" width="180">
                </el-table-column>
                <el-table-column prop="nickname" label="昵称" width="240">
                </el-table-column>
                <el-table-column label="头像" width="80">
                    <template slot-scope="scope">
                        <el-image style="width: 50px; height: 50px" :src="scope.row.img_url" fit="fill"
                            :preview-src-list="[scope.row.img_url]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="real_name" label="真实姓名" width="120">
                </el-table-column>
                <el-table-column prop="phone" label="电话" width="240">
                </el-table-column>
                <el-table-column prop="gender" label="性别">
                </el-table-column>
                <el-table-column prop="register_time" label="注册时间">
                </el-table-column>
                <el-table-column label="是否禁用">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.user_status" active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="send_count" label="剩余发布次数">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="text" @click="company_id = scope.row.id;company_form = scope.row;dialogFormVisible = true;">修改发布次数</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-empty v-show="tableData.length == 0" description="暂无入驻信息"></el-empty>
        <el-dialog title="发布次数" :visible.sync="dialogFormVisible">
            <el-form :model="company_form">
                <el-form-item label="次数" :label-width="formLabelWidth">
                    <el-input v-model="send_count" autocomplete="off" :placeholder="company_form.send_count"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirm_update_count()">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            page: 0,
            limit: 10,
            dialogFormVisible: false,
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            company_form:{
                send_count:0
            },
            send_count:"",
            company_id:0,
            formLabelWidth: '120px'
        }
    },
    mounted() {
        this.get_user_admin()
    },
    methods: {
        confirm_update_count(){
            const data = {
                uid: this.company_id,
                count: this.send_count
            }
            this.$request.post(this.$api.change_send_count_admin,data).then(res=>{
                if(res.code==0){
                    this.tableData = [];
                    this.get_user_admin();
                    this.$message.success("修改成功")
                    this.dialogFormVisible = false;
                    this.send_count = "";
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        get_user_admin() {
            this.$request.post(this.$api.get_user_admin, {
                page: this.page,
                limit: this.limit,
                type: 1
            }).then(res => {
                if (res.code == 0) {
                    res.data.forEach(item => {
                        item.register_time = new Date(item.register_time).toLocaleString()
                        if(item.user_status==1){
                            item.user_status=false
                        }else{
                            item.user_status=true
                        }
                        this.tableData.push(item)
                    })
                }
            })
        },
        handleApprove() {

        },
        handleDelete() {

        }
    }
}
</script>

<style>
.qiye1 {
    height: 80vh;
}
</style>