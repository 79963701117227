<template>
    <div>
        <el-button type="primary" size="mini" icon="el-icon-download" @click="get_excel_admin()">导出为表格</el-button>
        <div class="qiye1" >
            <el-table :data="tableData" height="100%" border style="width: 100%">
                <el-table-column prop="id" label="ID" width="180">
                </el-table-column>
                <el-table-column prop="nickname" label="昵称" width="240">
                </el-table-column>
                <el-table-column label="头像" width="80">
                    <template slot-scope="scope">
                        <el-image style="width: 50px; height: 50px" :src="scope.row.img_url" fit="fill"
                            :preview-src-list="[scope.row.img_url]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="real_name" label="真实姓名" width="120">
                </el-table-column>
                <el-table-column prop="phone" label="电话" width="240">
                </el-table-column>
                <el-table-column prop="gender" label="性别">
                </el-table-column>
                <el-table-column label="推广人数">
                <template slot-scope="scope">
                    <el-link type="primary" @click="to_user(scope.row.id)">查看</el-link>
                </template>
                </el-table-column>
                <el-table-column prop="register_time" label="注册时间">
                </el-table-column>
                <el-table-column label="是否禁用">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.user_status" active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="dialogFormVisible = true">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-empty v-show="tableData.length == 0" description="还没有用户"></el-empty>
        </div>
        <el-pagination :page-size="10" :pager-count="11" layout="prev, pager, next" @current-change="load_user" :total="totalCount">
        </el-pagination>
        <el-dialog title="推广详情" :visible.sync="dialogFormVisible">
      <el-form :model="form">
        <el-form-item label="推广人数">
          <span>{{ form.totalPromoters }}</span>
        </el-form-item>
        <el-table :data="form.promoters" style="width: 100%">
          <el-table-column label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="img_url" label="头像">
            <template slot-scope="scope">
              <img :src="scope.row.img_url" alt="头像" style="width: 30px; height: 30px; border-radius: 50%;">
            </template>
          </el-table-column>
          <el-table-column prop="nickname" label="昵称"></el-table-column>
          <el-table-column prop="register_time" label="推广时间"></el-table-column>
        </el-table>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            page: 0,
            limit: 10,
            dialogFormVisible: false,
            form: {
                totalPromoters: 0,
                promoters: []
            },
            formLabelWidth: '120px',
            totalCount:0
        }
    },
    mounted() {
        this.get_user_admin()
    },
    methods: {
        to_user(id){
            this.$request.post(this.$api.get_user_invite,{
                id:id
            }).then(res=>{
                if(res.code == 0){
                    this.form.totalPromoters = res.total;
                    this.form.promoters = res.data;
                    if(res.data.length == 0){
                        this.$message({
                            message: '该用户还没有推广人',
                            type: 'warning'
                        })
                    }
                    this.dialogFormVisible = true;
                }
            })
        },
        load_user(e){
            console.log(e);
            this.page = e-1;
            this.tableData = [];
            this.get_user_admin();
        },
        get_user_admin() {
            this.$request.post(this.$api.get_user_admin, {
                page: this.page,
                limit: this.limit,
                type: 0
            }).then(res => {
                if (res.code == 0) {
                    this.totalCount =res.total;
                    res.data.forEach(item => {
                        if (item.user_status == 1) {
                            item.user_status = false
                        } else {
                            item.user_status = true
                        }
                        this.tableData.push(item)
                    })
                }
            })
        },
        get_excel_admin() {
            this.$request.get(this.$api.get_excel_admin).then(res => {

            })
        },
        handleDelete() {

        }
    }
}
</script>

<style>
.qiye1 {
    height: 80vh;
}
</style>