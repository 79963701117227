<template>
  <div class="home">
    <el-container>
      <!-- 侧边栏 -->
      <el-aside class="home1" width="210px">
        <div class="home1_1">金毛后台管理</div>
        <el-menu default-active="/" background-color="#334154" active-text-color="#1989FA" class="el-menu-vertical-demo"
          text-color="#C0C4CC" :router="true">
          <el-menu-item index="/">
            <i class="el-icon-s-home"></i>
            <span slot="title">首页</span>
          </el-menu-item>
          <el-submenu index="/shenhe">
            <template  slot="title">
              <i class="el-icon-s-check"></i>
              <span>审核</span>
            </template>
            <el-menu-item index="/shenhe/qiye">
              <span slot="title">企业入驻</span>
            </el-menu-item>
            <el-menu-item index="/shenhe/gerenruzhu">
              <span slot="title">个人入驻</span>
            </el-menu-item>
            <el-menu-item index="/shenhe/gongzuoguanli">
              <span slot="title">工作管理</span>
            </el-menu-item>
            <el-menu-item index="/shenhe/fabubcishu">
              <span slot="title">发布次数管理</span>
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="/leaflet">
            <i class="el-icon-s-order"></i>
            <span slot="title">传单管理</span>
          </el-menu-item>
          <el-submenu index="user">
            <template slot="title">
              <i class="el-icon-user-solid"></i>
              <span>用户管理</span>
            </template>
            <el-menu-item index="/user/nomalluser">
              <span slot="title">普通用户</span>
            </el-menu-item>
            <el-menu-item index="/user/qiyeuser">
              <span slot="title">企业用户</span>
            </el-menu-item>
            <el-menu-item index="/user/gerenuser">
              <span slot="title">个人代理</span>
            </el-menu-item>
          </el-submenu>
          <el-submenu index="set">
            <template slot="title">
              <i class="el-icon-s-tools"></i>
              <span>设置</span>
            </template>
            <el-menu-item index="/set/swiper">
              <span slot="title">轮播图管理</span>
            </el-menu-item>
            <el-menu-item index="/set/baseset">
              <span slot="title">基础设置</span>
            </el-menu-item>
            <el-menu-item index="/set/jobtype">
              <span slot="title">工作类型</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <!-- 头部 -->
        <el-header class="home2" height="56px">
          <div class="home2_1">
            <div class="header">
              <el-avatar class="header_1">admin</el-avatar>
              <div class="header_2">管理员1</div>
            </div>
            <div>
              <el-dropdown @command="login_out">
                <div class="header1">
                  <i class="el-icon-setting"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item >退出登录</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-header>
        <!-- 中间部分 -->
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'HomeView',
  components: {
  },
  methods:{
    login_out(){
      localStorage.removeItem('access_token');
      this.$message.success('退出成功')
      this.$router.push('/login')
    }
  }
}
</script>

<style>
.home1 {
  height: 100vh;
  width: 200px;
  background-color: rgba(51, 65, 84, 1);
}
.el-menu{
  border: none !important;
}
.home2 {
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px 4px rgba(0, 21, 41, 0.12);
  position: relative;
  display: flex
}

.home2_1 {
  height: 56px;
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  padding-right: 40px;
}

.header {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.header_1 {
  margin-right: 10px;
}
.header1{
  font-size: 30px;
  color: #5B8FF9;
}
.home1_1 {
  color: rgba(64, 158, 255, 1);
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  padding-left: 60px;
  background: url(https://dyuan0527-1313117304.cos.ap-shanghai.myqcloud.com/mpJinMao%2Fjinmaologo.jpg) 20px center no-repeat;
  background-size: 35px 35px;
}

.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
  min-height: 400px;
}</style>
