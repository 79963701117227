<template>
  <div>
    <el-container>
      <el-main style="height: 100%; padding: 0;">
        <el-button type="primary" style="margin-bottom: 10px;" @click="addWorkType">添加</el-button>
        <el-table :data="workTypes" style="width: 100%; height: calc(100% - 40px);">
          <el-table-column label="序号" width="80">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column prop="value" label="名称" width="200"></el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <el-button type="danger" size="mini" @click="deleteWorkType(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
       <!-- 添加类型的弹窗 -->
       <el-dialog title="添加工作类型" :visible.sync="addDialogVisible">
        <el-form>
          <el-form-item label="类型名称">
            <el-input v-model="newWorkTypeName"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="addDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="add_subscribe_admin">保存</el-button>
        </div>
      </el-dialog>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      workTypes: [],
      addDialogVisible:false,
      newWorkTypeName: ''
    };
  },
  created() {
    this.get_job_type();
  },
  methods: {
    deleteWorkType(workType) {
      this.$confirm("确定删除该工作类型吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$request.post(this.$api.delete_subscribe_admin,{
            id: workType.id
          }).then(res=>{
            if(res.code == 0){
              this.$message.success('删除成功')
              this.get_job_type()
            }else{
              this.$message.error(res.msg)
            }
          })
        })
        .catch(
          // this.$message({
          //   type: "info",
          //   message: "已取消删除"
          // })
        );
    },
    addWorkType() {
      this.addDialogVisible = true;
    },
    add_subscribe_admin(){
      this.$request.post(this.$api.add_subscribe_admin,{
        name: this.newWorkTypeName,
        type: 1
      }).then(res=>{
        if(res.code == 0){
          this.$message.success('添加成功');
          this.newWorkTypeName = ''
          this.get_job_type()
          this.addDialogVisible = false
        }else{
          this.$message.error(res.msg)
        }
      })
    },
    
    get_job_type(){
      this.$request.post(this.$api.get_job_type,{
        type: 1
      }).then(res=>{
        this.workTypes = res.data
      })
    }
  }
};
</script>