<template>
    <div>
        <div class="qiye1" v-show="tableData.length>0">
            <el-table :data="tableData" height="100%" border style="width: 100%">
                <el-table-column prop="atime" label="申请日期" width="180">
                </el-table-column>
                <el-table-column prop="company_name" label="企业名称" width="240">
                </el-table-column>
                <el-table-column prop="company_leader" label="企业法人" width="120">
                </el-table-column>
                <el-table-column prop="address" label="企业地址" width="240">
                </el-table-column>
                <el-table-column prop="leader_name" label="项目负责人">
                </el-table-column>
                <el-table-column prop="leader_phone" label="负责人电话">
                </el-table-column>
                <el-table-column label="营业执照">
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.business_license"
                            fit="fill" :preview-src-list="[scope.row.business_license]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button v-show="scope.row.status==0" size="mini" type="primary" @click="handleApprove(scope.row)">通过</el-button>
                        <el-button v-show="scope.row.status==0" size="mini" type="danger" @click="handleDelete(scope.row)">拒绝</el-button>
                        <el-button v-show="scope.row.status==1" size="mini" type="success">已通过</el-button>
                        <!-- <el-button v-show="scope.row.status==1" size="mini" type="primary" icon="el-icon-edit">编辑</el-button> -->
                        <el-button v-show="scope.row.status==2" size="mini" type="info">已拒绝</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-empty v-show="tableData.length==0" description="暂无入驻信息"></el-empty>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            page: 0,
            limit: 10,
        }
    },
    mounted() {
        this.get_agent_admin()
    },
    methods: {
        get_agent_admin() {
            this.$request.post(this.$api.get_agent_admin, {
                page: this.page,
                limit: this.limit,
                type: 1
            }).then(res => {
                if(res.code==0){
                    res.data.forEach(item=>{
                        this.tableData.push(item)
                    })
                }
            })
        },
        handleApprove(item){
            this.$request.post(this.$api.approve_agent,{
                uid:item.uid,
                type:1,
                status:1,
                id:item.id
            }).then(res=>{
                if(res.code==0){
                    this.tableData=[];
                    this.get_agent_admin;
                    this.$message({
                        message:res.msg,
                        type:"success"
                    })
                }
            })
        },
        handleDelete(item){
            this.$request.post(this.$api.approve_agent,{
                uid:item.uid,
                id:item.id,
                type:2,
                status:1
            }).then(res=>{
                if(res.code==0){
                    this.tableData=[];
                    this.get_agent_admin();
                    this.$message({
                        message:res.msg,
                        type:"success"
                    })
                }
            })
        }
    }
}
</script>

<style>
.qiye1 {
    height: 80vh;
}
</style>