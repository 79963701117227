import axios from "axios";


const http = axios.create({

    baseURL:'https://jingmaojob.com:3000/',
    // baseURL:'http://127.0.0.1:3000/',
    timeout:2000,
    headers:{
        "Content-Type":"application/json"
    }
})
export default{
    post:(url,data)=>{
        return new Promise((resolve, reject) => {
            http.post(url,data).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err)
            })
        })
    },
    get:(url,data)=>{
        return new Promise((resolve, reject) => {
            http.get(url,data).then(res=>{
                resolve(res.data)
            }).catch(err=>{
                reject(err)
            })
        })
    },
}