<template>
    <div>
        <div class="qiye1" v-show="tableData.length>0">
            <el-table :data="tableData" height="100%" border style="width: 100%">
                <el-table-column prop="atime" label="申请日期" width="180">
                </el-table-column>
                <el-table-column prop="name" label="申请人姓名" width="240">
                </el-table-column>
                <el-table-column prop="phone" label="联系方式">
                </el-table-column>
                <el-table-column prop="count" label="申请次数">
                </el-table-column>
                <el-table-column prop="money" label="应付金额">
                </el-table-column>
                <el-table-column label="转账截图">
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.pay_img"
                            fit="fill" :preview-src-list="[scope.row.pay_img]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button v-show="scope.row.status==0" size="mini" type="primary" @click="handleApprove(scope.row.id, 1)">通过</el-button>
                        <el-button v-show="scope.row.status==0" size="mini" type="danger" @click="handleApprove(scope.row.id, 2)">拒绝</el-button>
                        <el-button v-show="scope.row.status==1" size="mini" type="success">已通过</el-button>
                        <el-button v-show="scope.row.status==2" size="mini" type="info">已拒绝</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-empty v-show="tableData.length==0" description="暂无入驻信息"></el-empty>
    </div>
</template>

<script>
 export default {
    data() {
        return {
            tableData: [],
            page: 0,
            limit: 100,
        }
    },
    mounted() {
        this.send_count_admin()
    },
    methods: {
        send_count_admin() {
            this.$request.post(this.$api.send_count_admin, {
                page: this.page,
                limit: this.limit,
                type: 2
            }).then(res => {
                if(res.code==0){
                    res.data.forEach(item=>{
                        this.tableData.push(item)
                    })
                }
            })
        },
        handleApprove(id,status){
            this.$request.post(this.$api.approve_send_count,{
                id:id,
                status:status
            }).then(res=>{
                if (res.code == 0) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.tableData = []
                    this.send_count_admin()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    });
                }
            })
        },
        handleDelete(){

        }
    }
}
</script>

<style>
.qiye1 {
    height: 80vh;
}
</style>