import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import IndexView from '../views/IndexView.vue'
import QiYeRuzhu from '../views/ShenHe/QiYeRuzhu.vue'
import GeRenRuzhu from '../views/ShenHe/GeRenRuzhu.vue'
import GongZuoGuanli from '../views/ShenHe/GongZuoGuanli.vue'
import LeafletView from '../views/LeafletView.vue'
import GeRenView from '../views/User/GeRenView.vue'
import QiYeView from '../views/User/QiYeView.vue'
import UserView from '../views/User/UserView.vue'
import SwiperView from '../views/SetMenu/SwiperView.vue'
import BaseView from '../views/SetMenu/BaseSetView.vue'
import FaBuCishu from '../views/ShenHe/FaBuCishu.vue'
import JobType from '../views/SetMenu/JobType.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect:'/index',
    children:[
        {
          path:"/index",
          name:"首页",
          component:IndexView
        },
        {
          path:'/shenhe/qiye',
          name:'企业入驻',
          component:QiYeRuzhu
        },
        {
          path:"/shenhe/gerenruzhu",
          name:"个人入驻",
          component:GeRenRuzhu
        },
        {
          path:"/shenhe/gongzuoguanli",
          name:"工作管理",
          component:GongZuoGuanli
        },
        {
          path:"/leaflet",
          name:"传单管理",
          component:LeafletView
        },
        {
          path:"/user/nomalluser",
          name:"普通用户",
          component:UserView
        },
        {
          path:"/user/qiyeuser",
          name:"企业用户",
          component:QiYeView
        },
        {
          path:"/user/gerenuser",
          name:"个人用户",
          component:GeRenView
        },
        {
          path:"/set/swiper",
          name:"轮播图",
          component:SwiperView
        },
        {
          path:"/set/baseset",
          name:"基础设置",
          component:BaseView
        },
        {
          path:"/shenhe/fabubcishu",
          name:"发布管理",
          component:FaBuCishu
        },
        {
          path:"/set/jobtype",
          name:"工作类型",
          component:JobType
        }
    ]
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})


import store from '@/store'
router.beforeEach((to,from,next)=>{
  const access_token = localStorage.getItem("access_token")
  if(!access_token&&to.name!=='login'){
    return next({path:"/login"})
  }
  next()
})

export default router
