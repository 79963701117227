<template>
    <div>
        <div class="qiye1">
            <el-table :data="tableData" height="100%" border fixed style="width: 100%">
                <el-table-column prop="id" label="ID" width="60">
                </el-table-column>
                <el-table-column prop="atime" label="发布日期" width="180">
                </el-table-column>
                <el-table-column prop="title" label="标题" width="240">
                </el-table-column>
                <el-table-column prop="start_time" label="开始时间" width="240">
                </el-table-column>
                <el-table-column prop="end_time" label="结束时间" width="240">
                </el-table-column>
                <el-table-column prop="details" label="工作详情" width="240">
                </el-table-column>
                <el-table-column prop="details" label="工作要求" width="240">
                </el-table-column>
                <el-table-column prop="require" label="工作地址" width="240">
                </el-table-column>
                <el-table-column prop="name" label="负责人" width="120">
                </el-table-column>
                <el-table-column prop="phone" label="负责人电话" width="180">
                </el-table-column>
                <el-table-column label="二维码" width="100">
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.qrcode" fit="fill"
                            :preview-src-list="[scope.row.qrcode]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="操作" fixed="right" width="240">
                    <template slot-scope="scope">
                        <el-button v-show="scope.row.job_status == 0" size="mini" type="primary"
                            @click="handleApprove(scope.row)">通过</el-button>
                        <el-button v-show="scope.row.job_status == 0" size="mini" type="danger" @click="handleJujue(scope.row.id)">拒绝</el-button>
                        <el-button v-show="scope.row.job_status == 1" size="mini" type="success">已通过</el-button>
                        <el-button v-show="scope.row.job_status == 1" size="mini" type="danger"
                        @click="handleDelete(scope.row.id)"  icon="el-icon-delete">删除</el-button>
                        <el-button v-show="scope.row.job_status == 2" size="mini" type="info">已拒绝</el-button>
                        <el-button v-show="scope.row.job_status == 3" size="mini" type="success">已下架</el-button>
                    </template>
                </el-table-column>
            </el-table>
        <el-empty v-show="tableData.length == 0" description="暂无相关工作信息"></el-empty>
        </div>
        <el-pagination :page-size="10" :pager-count="11" layout="prev, pager, next" @current-change="load_user" :total="totalCount">
        </el-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            page: 0,
            limit: 10,
            totalCount:0
        }
    },
    mounted() {
        this.get_job_admin()
    },
    methods: {
        load_user(e){
            console.log(e);
            this.page = e-1;
            this.tableData = [];
            this.get_job_admin();
        },
        get_job_admin() {
            this.$request.post(this.$api.get_job_admin, {
                page: this.page,
                limit: this.limit,
            }).then(res => {
                if (res.code == 0) {
                    this.totalCount =res.total;
                    res.data.forEach(item => {
                        this.tableData.push(item)
                    })
                }
            })
        },
        handleJujue(id){
            this.$request.post(this.$api.approve_work, {
                status: 2,
                id: id
            }).then(res => {
                if (res.code == 0) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.tableData = []
                    this.get_job_admin()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    });
                }
            })
        },
        handleApprove(row) {
            this.$request.post(this.$api.approve_work, {
                status: 1,
                id: row.id,
                subscribe_ids:row.subscribe_ids
            }).then(res => {
                if (res.code == 0) {
                    this.$message({
                        message: res.msg,
                        type: 'success'
                    });
                    this.tableData = []
                    this.get_job_admin()
                } else {
                    this.$message({
                        message: res.msg,
                        type: 'error'
                    });
                }
            })
        },
        delete_job_admin(id){
            this.$request.post(this.$api.delete_job_admin,{
                id:id
            }).then((res)=>{
                if(res.code==0){
                    this.tableData=[];
                    this.get_job_admin();
                    this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
                }else{
                    this.$message({
                    type: 'info',
                    message: '删除失败'
                });
                }
            })
        },
        handleDelete(id) {
            let that = this;
            this.$confirm('此操作将永久删除该工作, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                that.delete_job_admin(id)
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        }
    }
}
</script>

<style>
.qiye1 {
    height: 100%;
}
</style>