<template>
    <div>
        <div class="qiye1" v-show="tableData.length > 0">
            <el-table :data="tableData" height="100%" border style="width: 100%">
                <el-table-column prop="id" label="ID" width="180">
                </el-table-column>
                <el-table-column prop="nickname" label="昵称" width="240">
                </el-table-column>
                <el-table-column label="头像" width="80">
                    <template slot-scope="scope">
                        <el-image style="width: 50px; height: 50px" :src="scope.row.img_url" fit="fill"
                            :preview-src-list="[scope.row.img_url]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="real_name" label="真实姓名" width="120">
                </el-table-column>
                <el-table-column prop="phone" label="电话" width="240">
                </el-table-column>
                <el-table-column prop="gender" label="性别">
                </el-table-column>
                <el-table-column prop="register_time" label="注册时间">
                </el-table-column>
                <el-table-column label="是否禁用">
                    <template slot-scope="scope">
                        <el-switch v-model="scope.row.user_status" active-color="#13ce66" inactive-color="#ff4949">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="toUpdate(scope.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-empty v-show="tableData.length == 0" description="暂无入驻信息"></el-empty>
        <el-dialog title="用户信息" :visible.sync="dialogFormVisible">
            <el-form :model="form">
                <el-form-item label="用户昵称" :label-width="formLabelWidth">
                    <el-input v-model="form.nickname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用户头像" :label-width="formLabelWidth">
                    <el-image style="width: 50px; height: 50px" :src="form.img_url" fit="cover"
                            :preview-src-list="[form.img_url]"></el-image>
                </el-form-item>
                <el-form-item label="真实姓名" :label-width="formLabelWidth">
                    <el-input v-model="form.real_name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用户等级" :label-width="formLabelWidth">
                    <el-input v-model="form.status_name" autocomplete="off"  :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="发布次数" :label-width="formLabelWidth" v-show="form.status==2">
                    <el-input v-model="form.send_count" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" :label-width="formLabelWidth">
                    <el-input v-model="form.phone" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="所在学校" :label-width="formLabelWidth">
                    <el-input v-model="form.school" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="个人简介" :label-width="formLabelWidth">
                    <el-input v-model="form.desc" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="收款方式" :label-width="formLabelWidth">
                    <el-image style="width: 100px; height: 100px" :src="form.payment_qrcode" fit="cover"
                            :preview-src-list="[form.payment_qrcode]"></el-image>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button  @click="dialogFormVisible = false">取消</el-button>
                <el-button type="primary" @click="dialogFormVisible = false">修改</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            page: 0,
            limit: 10,
            dialogFormVisible: false,
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            formLabelWidth: '120px'
        }
    },
    mounted() {
        this.get_user_admin()
    },
    methods: {
        get_user_admin() {
            this.$request.post(this.$api.get_user_admin, {
                page: this.page,
                limit: this.limit,
                type: 2
            }).then(res => {
                if (res.code == 0) {
                    res.data.forEach(item => {
                        if(item.user_status==1){
                            item.user_status=false
                        }else{
                            item.user_status=true
                        }
                        this.tableData.push(item)
                    })
                }
            })
        },
        handleApprove() {

        },
        toUpdate(e){
            this.form=e;
            this.dialogFormVisible= true;
        },
        handleDelete() {

        }
    }
}
</script>

<style>
.qiye1 {
    height: 80vh;
}
</style>