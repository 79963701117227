<template>
    <div>
        <el-button style="margin-bottom: 20px;" size="mini" type="primary" @click="dialogVisible = true">新增</el-button>
        <div class="qiye1" v-show="tableData.length > 0">
            <el-table :data="tableData" height="100%" style="width: 100%">
                <el-table-column prop="id" label="ID" width="180">
                </el-table-column>
                <el-table-column label="图片" width="240">
                    <template slot-scope="scope">
                        <el-image style="width: 100px; height: 100px" :src="scope.row.cover" fit="fill"
                            :preview-src-list="[scope.row.cover]"></el-image>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="delete_swiper(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
            <el-upload class="avatar-uploader"
                action="https://dyuankang.cn:3000/uploads/upload" :show-file-list="false" :on-success="handleAvatarSuccess1"
                name="image" :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl1" :src="imageUrl1" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="add_swiper()">确 定</el-button>
            </span>
        </el-dialog>
        <el-empty v-show="tableData.length == 0" description="暂无入驻信息"></el-empty>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            page: 0,
            limit: 10,
            dialogVisible: false,
            imageUrl1:""
        }
    },
    mounted() {
        this.get_swiper()
    },
    methods: {
        get_swiper() {
            this.$request.post(this.$api.get_swiper, {

            }).then(res => {
                if (res.code == 0) {
                    res.data.forEach(item => {
                        this.tableData.push(item)
                    })
                }
            })
        },
        handleAvatarSuccess1(res, file) {
            this.imageUrl1 = res.filename;
        },
        add_swiper() {
            this.$request.post(this.$api.add_swiper,{
                cover:this.imageUrl1
            }).then(res=>{
                if(res.code==0){
                    this.$message({
                        message:"保存成功",
                        type:"success"
                    })
                    this.dialogVisible = false
                    this.tableData=[]
                    this.get_swiper()
                }else{
                    this.$message({
                        message:"添加失败",
                        type:"error"
                    })
                }
            })
        },
        delete_swiper(id) {
            this.$request.post(this.$api.delete_swiper,{
                id:id
            }).then(res=>{
                if(res.code==0){
                    this.$message({
                        message:"操作成功",
                        type:"success"
                    })
                    this.tableData=[]
                    this.get_swiper()
                }else{
                    this.$message({
                        message:"添加失败",
                        type:"error"
                    })
                }
            })
        }
    }
}
</script>

<style scoped>
.qiye1 {
    width: 600px;
    /* height: 80vh; */
}
.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px !important;
    text-align: center;
}
</style>